import React from 'react'
import { Link } from 'gatsby'

import Container from 'react-bootstrap/Container'
import Obfuscate from 'react-obfuscate'
import Layout from '../../components/layout'
import Seo from '../../components/seo'

const TermsOfUse = () => {
  const email = (
    <Obfuscate
      email="dmca@meati.com"
      obfuscateChildren
      linkText="dmca@meati.com"
      headers={{
        subject: 'Terms of Use inquiry from meati.com',
      }}
      aria-label="Email Meati™"
    >
      dmca@meati.com
    </Obfuscate>
  )

  return (
    <Layout>
      <Seo title="Terms of Use" noindex />

      <section className="py-3 py-lg-4 py-xl-5 border-bottom legal-doc" id="termsOfService">
        <Container>
          <h1 className="font-swish">Terms of Use</h1>
          <p>Effective: 2/14/2022</p>

          <h2 className="text-uppercase">Acceptance of Terms </h2>
          <p>
            This website is owned and operated by Emergy Inc. d/b/a Meati (&ldquo;{' '}
            <strong>Meati,&rdquo; &ldquo;we&rdquo;</strong>or <strong>&ldquo;us&rdquo;</strong>). These Terms of Use
            (&ldquo;<strong>Terms of Use</strong>&rdquo;) set forth the terms under which you are authorized to use our
            website (which includes any website on which these Terms of Use are posted, and other sites accessible via
            subdomains, and mobile versions of any of these sites) (collectively &ldquo;
            <strong>Website</strong>
            &rdquo;).
          </p>
          <p>
            Please read these Terms of Use carefully. By accessing this Website, you agree that you have read and
            understand these Terms of Use and any posted updates, and that you accept and agree to be bound by them. If
            you do not wish to be bound by these Terms of Use, please do not continue to use this Website.
          </p>

          <h2 className="text-uppercase">Additional Terms</h2>
          <p>
            Your use of certain features of our Website may be subject to additional terms. For example, if you purchase
            a product from us through our Website, it may be subject to our Refund Policy below. We may offer promotions
            through the Website from time to time, and your participation in these may also be subject to additional
            terms. To the extent additional terms affect your use of the Website, those terms (including
            our&nbsp;Privacy Policy) are hereby incorporated by reference into these Terms of Use.
          </p>

          <h2 className="text-uppercase">Registration</h2>
          <p>
            You may browse the Website and purchase our products without registration, but you understand that some
            features of the Website may not be accessible to you unless you register. In registering for the Website,
            you agree to: (a) provide true, accurate, current and complete information about yourself as prompted by the
            registration form (the &ldquo;<strong>Registration Data</strong>&rdquo;); (b) maintain and promptly update
            the Registration Data to keep it true, accurate, current and complete; and (c) certify that you are at least
            eighteen (18) years old. If you provide any information that is untrue, inaccurate, not current or
            incomplete, or if we have reasonable grounds to suspect this, we have the right to suspend or terminate your
            account and to refuse any further use of the Website by you.
          </p>

          <h2 className="text-uppercase">Registration Account and Password</h2>
          <p>
            You will use your email and a password to manage your account. You are responsible for maintaining the
            confidentiality of your password and account and all activities that occur in connection with these. You
            agree to immediately notify us of any actual or suspected unauthorized use of your password or account or
            any other breach of security. We will not be liable for any loss or damage arising from your failure to
            protect the confidentiality of your password and account.
          </p>

          <h2 className="text-uppercase">Protection of Privacy</h2>
          <p>
            Any personal information we may collect or process in connection with your use of our Website or our related
            services is subject to our
            <Link
              to="/legal/privacy-policy"
              className="ad-el-tracked"
              data-ad-element-type="text link"
              data-ad-text="url-privacy-policy"
              data-ad-button-location="header"
              data-ad-button-id="version 1"
            >
              Privacy Policy
            </Link>
            , which is incorporated into these Terms of Use. We encourage you to read and understand our privacy
            practices before continuing. By agreeing to these Terms of Use, you are also consenting to our use of your
            personal information in accordance with our Privacy Policy.
          </p>

          <h2 className="text-uppercase">Products for Personal Use</h2>
          <p>
            All products available on this Website, and any samples thereof, are for personal, non-commercial use only.
            You may not resell any of the products that you purchase or receive from us. We reserve the right, with or
            without notice, to cancel or reduce the quantity of product ordered, in our sole discretion, if we determine
            your order violates our Terms of Use. If you are a wholesaler or retailer interested in purchasing items in
            bulk, please reach out to us by using our contact form at
            <Link
              to="/contact"
              className="ad-el-tracked"
              data-ad-element-type="text link"
              data-ad-text="url-contact"
              data-ad-button-location="header"
              data-ad-button-id="version 1"
            >
              https://meati.com/contact
            </Link>
            .
          </p>

          <h2 className="text-uppercase">Product Descriptions</h2>
          <p>
            Meati attempts to be as accurate as possible with all of our product descriptions. However, Meati does not
            warrant that product descriptions, photos, or other content of any product is accurate, complete, reliable,
            current, or error-free. The products delivered may vary from the images and descriptions viewed on the site
            due to a number of factors that are not within our control, including without limitation, system
            capabilities and constraints of your computer, manufacturing process issues, and the availability and
            variability of product, packaging, and raw materials. Although we will exercise commercially reasonable
            efforts to help ensure that the products conform to reasonable expectations, variations may sometimes occur.
            If a product offered by Meati itself is not as described, your sole remedy is to receive a credit or refund
            for the product according to the Refund Policy below.
          </p>

          <h2 className="text-uppercase">REFUND POLICY</h2>
          <p>
            If you are dissatisfied with any product, please reach out to us by using our contact form at
            <Link
              to="/contact"
              className="ad-el-tracked"
              data-ad-element-type="text link"
              data-ad-text="url-contact"
              data-ad-button-location="header"
              data-ad-button-id="version 1"
            >
              https://meati.com/contact
            </Link>{' '}
            and our team will respond to your query and, depending on the circumstances, we will provide you a
            replacement, a full or partial refund of the purchase price for that an Order or product, or a full or
            partial credit of the purchase price for that Order or product. We may require the return or photographic
            documentation of any product with which you are dissatisfied before we provide you a replacement, credit, or
            refund. Once processed, please allow the refund amount to appear in your account after 5-10 business days.
          </p>
          <p>
            Meati reserves the right to refuse service, refuse distribution of credits or refunds, terminate accounts,
            terminate your rights to use Meati, or cancel Orders in its sole discretion.
          </p>

          <h2 className="text-uppercase">PAYMENT AND BILLING INFORMATION</h2>
          <p>
            You agree to pay the price applicable for all products you order and you agree to pay all applicable fees,
            including but not limited to delivery fees and taxes related to your use of our Website and services. We may
            suspend or terminate your account and/or access to our services if your payment is late and/or your credit
            card cannot be processed. By providing a credit or debit card, you represent and warrant that you are
            authorized to use the designated payment method and that you authorize us (or our third-party payment
            processor) to charge your payment method for the total amount of your subscription or other purchase
            (including any applicable taxes and other charges) (collectively, as applicable, an &ldquo;Order&rdquo;). If
            the payment method cannot be verified, is invalid, or is otherwise not acceptable, your Order may be
            suspended or canceled.
          </p>

          <h2 className="text-uppercase">DELIVERIES</h2>
          <p>
            You are responsible for inspecting all products you receive from us for any damage or other issues upon
            delivery. You should properly store (including refrigeration) all items you receive. If you are not home
            when a delivery arrives, the delivery person may leave the package for you at your door, or in your
            building&rsquo;s lobby, at its front desk, or in its mailroom, etc. Anyone at the delivery address who
            receives the delivery is conclusively presumed to be authorized to receive the delivery. In cases in which
            you have designated an alternative receiver, such person shall accept the products under all of the same
            terms and conditions that would apply had you accepted the delivery yourself. If you do not receive your
            delivery, if you are missing items, or if you are not completely satisfied with your order, please reach out
            to us by using our contact form at
            <Link
              to="/contact"
              className="ad-el-tracked"
              data-ad-element-type="text link"
              data-ad-text="url-contact"
              data-ad-button-location="header"
              data-ad-button-id="version 1"
            >
              https://meati.com/contact
            </Link>{' '}
            within 24 hours and our team will respond to your query to resolve the issue.
          </p>
          <p>
            We are not responsible for (i) items delivered to incorrect addresses supplied during ordering; (ii)
            delivery issues and quality issues arising from the recipient not being present at the time of delivery at
            the address supplied during ordering; (iii) decreased product quality due to an incorrect delivery address
            supplied during ordering, or a re-route requested by the recipient; (iv) product quality problems caused by
            improper handling by the recipient.
          </p>
          <p>
            There may be some instances, as a result of inclement weather, natural or national disaster, or other
            unforeseen circumstances, that your Order cannot be made on your scheduled delivery day. In those cases, we
            will make our best effort to deliver your Order as close as possible to the assigned delivery day. In the
            case that we cannot deliver your Order at all, we will notify you and credit your account for the missed
            delivery if necessary.
          </p>

          <h2 className="text-uppercase">DISCLAIMER OF WARRANTIES</h2>
          <p>
            TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE WEBSITE IS PROVIDED ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS
            AVAILABLE&rdquo; BASIS, UNLESS OTHERWISE SPECIFIED IN WRITING. WITH RESPECT TO THIS WEBSITE (INCLUDING BUT
            NOT LIMITED TO THE OPERATION OF THE WEBSITE, OR THE INFORMATION, CONTENT, MATERIALS, PRODUCT DESCRIPTIONS,
            AND SERVICES DESCRIBED THROUGH THE WEBSITE) AND THE PRODUCTS OFFERED FOR SALE ON IT, MEATI MAKES NO
            REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE
            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT OF THIRD-PARTY
            RIGHTS. IN NO EVENT WILL MEATI BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES
            RESULTING FROM YOUR PURCHASE, EVEN IF MEATI HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            MEATI&rsquo;S ENTIRE LIABILITY FOR ANY PRODUCT SALE WILL NOT EXCEED THE TOTAL AMOUNT ACTUALLY RECEIVED BY
            MEATI FROM SUCH SALE.
          </p>

          <h2 className="text-uppercase">Meati's Intellectual Property Rights</h2>
          <p>
            All content, copyrights and other intellectual property rights in the content available on our Website,
            including without limitation Website design, text, graphics, interfaces, and the selection and arrangements
            thereof, are owned by Meati, with all rights reserved, or in some cases may be licensed to Meati by third
            parties. This content is protected by the intellectual property rights of Meati or those owners. All content
            which qualifies for protection under Federal Copyright Law under the laws of the United States is subject to
            the exclusive jurisdiction of the Federal Court System, whether registered or unregistered. All trademarks
            displayed on the Website are the trademarks of their respective owners, and constitute neither an
            endorsement nor a recommendation of such parties. In addition, such use of trademarks or links to the web
            sites of third parties is not intended to imply, directly or indirectly, that those third parties endorse or
            have any affiliation with Meati.
          </p>

          <h2 className="text-uppercase">Information Sent to Us</h2>
          <p>
            We welcome and encourage your feedback, but please do not submit any proprietary or confidential
            information, suggestions or materials via email, our Website, or any other method. We will not compensate
            you for any submissions that we use. By submitting such materials, you grant Meati a perpetual, worldwide,
            irrevocable, royalty-free license to use those materials in any way Meati deems appropriate. You expressly
            agree that you will not submit any materials that you do not have the right to make available under any law
            or contractual or fiduciary relationships (such as inside information, proprietary and confidential
            information learned or disclosed as part of employment relationships or under nondisclosure agreements), and
            you will solely bear any liability related to your submission of such materials.
          </p>

          <h2 className="text-uppercase">User Submitted Content and User Conduct</h2>
          <p>
            Any content you have made available to us, whether uploaded, posted, submitted, or otherwise provided to us
            or on our Website (including an social media pages), including without limitation your reviews of products,
            content from your social media account(s), or any other content which does not originate with us (&ldquo;
            <strong>User Content</strong>
            &rdquo;), is your sole responsibility. You represent and warrant that you own any User Content or have
            permission to provide any User Content that you make available to us. Under no circumstances will Meati be
            liable in any way for any User Content made available on or through our Website.
          </p>
          <p>
            Meati does not claim any copyrights in original works created and/or posted by you. However, by uploading,
            posting, transmitting or otherwise making any User Content available on or through our Website or social
            media, you are granting us, and our related entities, an irrevocable, nonexclusive, royalty-free license to
            copy, modify, publish, distribute publicly, and prepare derivative works of such User Content in any medium
            or format without any obligation of notice, attribution or compensation to you.
          </p>
          <p>
            We may not moderate User Content on certain other areas of our Website and social media pages, so we cannot
            and do not warrant and/or guarantee the truthfulness, integrity, suitability, or quality of any publicly
            available User Content.
          </p>
          <p>
            You agree that you will not use our Website or social media pages to transmit or make available any content
            that:
          </p>
          <ul>
            <li>
              violates any laws, contains any threats, is abusive, harassing, vulgar, obscene, indecent, violates any
              person's rights of privacy or publicity, is defamatory, libelous, hateful, contains any disparaging
              statements or opinions regarding racial, gender or ethnic background, or is otherwise tortious or
              objectionable;
            </li>
            <li>
              infringes any intellectual property rights or other rights of any party, including, but not limited to any
              patent, trademark, trade secret, copyright or other proprietary rights;
            </li>
            <li>
              contains any private information about an identifiable person without that person's permission(or in the
              case of the deceased, the permission of a family member or executor), or any content soliciting any
              personal or private information from any individual;
            </li>
            <li>you know or have reason to know is false, misleading, or fraudulent;</li>
            <li>
              you do not have a right to make available under any law or under contractual or fiduciary relationships
              (such as inside information or proprietary and confidential information learned or disclosed as part of
              employment relationships or under nondisclosure agreements);
            </li>
            <li>employs any techniques to disguise the origin of the content submitted;</li>
            <li>
              contains any unsolicited or unauthorized advertising, promotional materials, or material which can be
              characterized as "junk mail," "spam," "chain letters," "pyramid schemes," "Ponzi schemes" or similar
              material, or any information posted primarily for advertising, promotional, or other commercial purposes,
              including without limitation any request for or solicitation of money, goods, or services for private
              gain;
            </li>
            <li>
              incorporates within it any software viruses or any other computer code, files or programs whose purpose or
              function is to interrupt, destroy or otherwise impair the operability of any software or hardware or
              telecommunications equipment; or
            </li>
            <li>
              contains links to any websites containing content violating any of the foregoing requirements, or links to
              any websites for purposes of disrupting the operations of such website, harassing the owners of such
              website, or other objectionable or illegal purposes.
            </li>
          </ul>
          <p>You further agree not to use our Website to:</p>
          <ul>
            <li>engage in any conduct which might be harmful to any individual;</li>
            <li>
              engage in any conduct which is exploitative or disrespectful to friends and family members of the deceased
            </li>
            <li>impersonate or misrepresent your affiliation with any person or entity;</li>
            <li>
              engage in or facilitate any conduct that is deemed, or found by any court or similar agency, to constitute
              "stalking" or otherwise harassing conduct aimed at another person or entity; or
            </li>
            <li>
              engage in or transmit any material that encourages conduct that could constitute a criminal offense, give
              rise to civil liability or otherwise violate any applicable local, state, national, or international law
              or regulation-- including, without limitation, laws and regulations related to the handling of human or
              animal remains.
            </li>
          </ul>
          <p>
            In addition, you are prohibited from interfering with, creating an excessive burden on, or otherwise
            disrupting our Website, servers or networks connected thereto (e.g., using a bot to overload our product
            review feature). We reserve the right (but have no obligation) in our sole discretion to pre-screen, edit,
            refuse, move or remove any User Content that is posted on our Website or prohibit your access to our Website
            in the event of a violation of these Terms of Use.
          </p>
          <p>
            If you believe that any content on our Website or social media pages violates these Terms of Use or is
            otherwise inappropriate, please report the content by completing the Contact Us form on the applicable site,
            or reach out to us by using our contact form at
            <Link
              to="/contact"
              className="ad-el-tracked"
              data-ad-element-type="text link"
              data-ad-text="url-contact"
              data-ad-button-location="header"
              data-ad-button-id="version 1"
            >
              https://meati.com/contact
            </Link>
            .
          </p>

          <h2 className="text-uppercase">Notification of Claimed Copyright Infringement</h2>
          <p>
            In the event that you find content posted on our Website which you believe is an infringement of the
            copyright ownership or other intellectual property rights of yourself or any third party, please immediately
            contact our Copyright Agent as described below. To report any alleged infringement, you may contact us in
            writing by providing a signed statement containing the following information:
          </p>
          <ol>
            <li>
              your name, address, telephone number, and e-mail address, and if you are acting on behalf of the owner of
              the intellectual property, the name of the owner;
            </li>
            <li>
              a statement, made under penalty of perjury, that you are the owner of the copyright or are authorized to
              act on behalf of the owner;
            </li>
            <li>
              a detailed description of the copyrighted work or other intellectual property that you claim has been
              infringed;
            </li>
            <li>
              if your claim is based on a registered work, the registration number, and the date of issuance of the
              registration;
            </li>
            <li>
              a description of the infringing material and the URL where such material is located on the Site, or a
              description of where on our Site you found such material;
            </li>
            <li>
              your written statement that you believe, in good faith, that the use of the work on our Site has not been
              authorized by the true owner of the work, its agent, or as a matter of law; and
            </li>
            <li>a statement under penalty of perjury that all of the information you have provided is true.</li>
          </ol>
          <p>
            Please send your notice of alleged infringement to us by email at
            {email}.
          </p>
          <p>
            In accordance with the Digital Millennium Copyright Act (&ldquo;
            <strong>DMCA</strong>
            &rdquo;), it is our policy to terminate use of our Website by repeat infringers in appropriate
            circumstances. We reserve this right for all users, regardless of their geographic location.
          </p>

          <h2 className="text-uppercase">INDEMNIFICATION</h2>
          <p>
            UNLESS OTHERWISE PROHIBITED BY LAW, YOU AGREE TO DEFEND, INDEMNIFY AND HOLD MEATI AND ITS SUBSIDIARIES,
            AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS AND PARTNERS HARMLESS FROM AND AGAINST ANY AND ALL
            CLAIMS, ACTIONS, LIABILITIES, COSTS OR DAMAGES (INCLUDING REASONABLE ATTORNEYS&rsquo; FEES) ASSERTED BY ANY
            THIRD PARTY ARISING FROM OR RELATED TO YOUR MISUSE OF THIS WEBSITE OR YOUR MISCONDUCT IN CONNECTION
            THEREWITH IN VIOLATION OF THESE TERMS OF USE.
          </p>

          <h2 className="text-uppercase">Links to Other Sites</h2>
          <p>
            Meati does not make representations or warranties about any website that you may be able to access through
            this Website. Meati does not endorse, monitor or assume any liability for the information provided or
            activities undertaken by any such website.
          </p>

          <h2 className="text-uppercase">Arbitration</h2>
          <p>
            Any dispute arising out of or relating in any way to your use of our Website or any products, services, or
            information you receive through our Website, shall be submitted to confidential, binding arbitration in
            Denver, Colorado, USA, pursuant to the American Arbitration Association&rsquo;s Commercial Arbitration
            Rules. The arbitrator's award shall be final and binding and may be entered as a judgment in any court of
            competent jurisdiction. No arbitration under these Terms of Use may be joined with another arbitration
            related to the subject matter hereof. Notwithstanding the foregoing arbitration requirement, with regard to
            any actual or potential violation of our intellectual property rights, we may seek injunctive or other
            appropriate relief in the courts of Colorado, USA, or the courts closest thereto, and you hereby consent to
            the exclusive jurisdiction of such courts and waive all objections thereto.&nbsp;
          </p>
          <p>
            Notwithstanding the above, you or we may choose to pursue a dispute in court and not by arbitration if: (a)
            The dispute qualifies for initiation in small claims court; (b) our intellectual property rights are at
            issues; or (c) YOU OPT-OUT OF THESE ARBITRATION PROCEDURES WITHIN 30 DAYS FROM THE DATE THAT YOU FIRST
            CONSENT TO THESE TERMS (the &ldquo;Opt-Out Deadline&rdquo;). You may opt-out of this Provision by submitting
            a completed form on our Contact page with the following information: (1) your name; (2) your address; (3) a
            clear statement that you do not wish to resolve disputes with us through arbitration. We do have to enforce
            the Opt-Out Deadline so please keep in mind that any opt-out request received after the Opt-Out Deadline
            will not be valid and you must pursue your dispute in arbitration or small claims court.
          </p>

          <h2 className="text-uppercase">Class Action Waiver</h2>
          <p>
            Except as otherwise provided in herein, the arbitrator may not consolidate more than one person&rsquo;s
            claims, and may not otherwise preside over any form of a class or representative proceeding or claims (such
            as a class action, consolidated action or private attorney general action) unless both you and we
            specifically agree to do so following initiation of the arbitration. If you choose to pursue your dispute in
            court by opting out of the Arbitration Provision, as specified herein, this Class Action Waiver will not
            apply to you. Neither you, nor any other user of the Website can be a class representative, class member, or
            otherwise participate in a class, consolidated, or representative proceeding without having complied with
            the opt-out requirements above.
          </p>

          <h2 className="text-uppercase">Governing Laws</h2>
          <p>
            The laws of the state of Colorado and the United States govern these Terms of Use and any claims arising out
            of or relating to use of the Website, without giving effect to any choice of law rules. We make no
            representation that our Website is appropriate, legal or available for use in all jurisdictions. With the
            exception of any matters subject to arbitration as described above, the state and federal courts located in
            Colorado, will serve as the venue for any actions brought, or claims made, arising out of your use of our
            Website.
          </p>

          <h2 className="text-uppercase">Local Laws</h2>
          <p>
            We control and operate the Website from our headquarters in the United States of America and the entirety of
            the Website may not be appropriate or available for use in other locations. If you use the Website from
            outside the United States of America, you are solely responsible for following applicable local laws.
          </p>

          <h2 className="text-uppercase">Changes to these Terms</h2>
          <p>
            We reserve the right, at any time, to modify, alter, or update these Terms of Use without prior notice. You
            are encouraged to check this page regularly for changes to the Terms of Use. Modifications will become
            effective immediately upon being posted to our Website, without further notice to you. Your continued use of
            our Website after such modifications are posted constitutes an acknowledgement and acceptance of such
            modifications, and you may not amend these Terms of Use.
          </p>

          <h2 className="text-uppercase">Other Terms</h2>
          <p>
            If any provision of these Terms of Use is determined by a court of law to be unlawful, void or unenforceable
            for any reason, the other provisions (and any partially-enforceable provision) shall not be affected thereby
            and shall remain valid and enforceable to the maximum possible extent, and the invalid provision will be
            modified as necessary to make it valid and enforceable while as closely as possible reflecting the original
            intentions of Meati. You agree that these Terms of Use and any other agreements referenced herein may be
            assigned by us, in our sole discretion, to a third party in the event of a merger or acquisition or
            otherwise. These Terms of Use shall apply in addition to, and shall not be superseded by, any other written
            agreement between us in relation to your participation as a contributor to our Website. You agree and
            understand that these Terms of Use together with any other applicable click-through agreements you may have
            entered into regarding the Website, constitutes the entire agreement between you and Meati regarding your
            use of the Website, and that any other prior agreements between you and Meati are superseded by these Terms
            of Use to the extent applicable. Any failure by Meati to exercise its rights under these Terms of Use or to
            enforce the terms hereof will not constitute a waiver of those rights. You agree that regardless of any
            statute or law to the contrary, any claim or cause of action arising out of or related to use of the Website
            or relating to these Terms of Use must be filed within one (1) year after such claim or cause of action
            arose or be forever barred.
          </p>

          <h2 className="text-uppercase">Contact Us</h2>
          <p>
            If you have any questions about these Terms of Use, please feel free to contact us by using our contact form
            at
            <Link
              to="/contact"
              className="ad-el-tracked"
              data-ad-element-type="text link"
              data-ad-text="url-contact"
              data-ad-button-location="header"
              data-ad-button-id="version 1"
            >
              https://meati.com/contact
            </Link>
            .
          </p>
        </Container>
      </section>
    </Layout>
  )
}

export default TermsOfUse
